import { where, query, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc } from "firebase/firestore";
import { affaireCollection, deletedItemsCollection } from './firebase';

export const getAffaires = async () => {
    try {
        const querySnapshot = await getDocs(query(affaireCollection));
        const affaires = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            return {
                ...data,
                createDate: data.createDate?.toDate(), // Convert Firestore Timestamp to JavaScript Date
            };
        });

        // Sort affaires by createDate (newest first)
        affaires.sort((a, b) => {
            if (a.createDate && b.createDate) {
                return b.createDate - a.createDate; // Sort in descending order (newest first)
            }
            return 0; // If createDate is missing for any item, keep the original order
        });

        // console.log(affaires); // Debugging: Check the sorted affaires

        return affaires;
    } catch (error) {
        console.error("Error fetching affaires:", error); // Log the error for debugging
        return [];
    }
};

export const editAffaire = async (affaireId, codeAffaire, nameAffaire, tache) => {
    try {
        const adminName = localStorage.getItem('admin_name');

        let newData = {
            codeAffaire: codeAffaire,
            nameAffaire: nameAffaire,
            tache: tache,
            editedBy: `Edited by ${adminName} at this date: ${new Date().toISOString()}`
        };

        // function to get the data 
        const checkAffaireExists = async (field, value) => {
            const affairesQuery = query(
                affaireCollection,
                where(field, '==', value)
            );

            const querySnapshot = await getDocs(affairesQuery);
            return querySnapshot.docs.map((doc) => doc.data());
        };

        const existingAffaireByCode = await checkAffaireExists('codeAffaire', codeAffaire);
        const existingAffaireByName = await checkAffaireExists('nameAffaire', nameAffaire);

        const affaireData = (await getDoc(doc(affaireCollection, affaireId))).data();

        if (affaireData.codeAffaire !== codeAffaire && existingAffaireByCode.length > 0) {
            return 0;
        } else if (affaireData.nameAffaire !== nameAffaire && existingAffaireByName.length > 0) {
            return 0;
        } else {
            await setDoc(doc(affaireCollection, affaireId), newData, { merge: true });
        }
    } catch (error) {
        return 0;
    }
};

export const addAffaire = async (code, name, date, tache) => {
    try {
        const adminName = localStorage.getItem('admin_name');

        const data = {
            codeAffaire: code,
            nameAffaire: name,
            createdBy: adminName,
            tache: tache,
            createDate: new Date(date)
        }

        const checkAffaireExists = async (field, value) => {
            const affairesQuery = query(
                affaireCollection,
                where(field, '==', value)
            );

            const querySnapshot = await getDocs(affairesQuery);
            return querySnapshot.docs.map((doc) => doc.data());
        }

        const existingAffaireByCode = await checkAffaireExists('codeAffaire', code);
        const existingAffaireByName = await checkAffaireExists('nameAffaire', name);

        if (existingAffaireByCode.length > 0) {
            return '0';
        } else if (existingAffaireByName.length > 0) {
            return '1';
        } else {
            const affaireRef = await addDoc(affaireCollection, data);
            await setDoc(doc(affaireCollection, affaireRef.id), { affaire_id: affaireRef.id }, { merge: true });
            return '2';
        }
    } catch (error) {
        return '3';
    }
};

export const deleteAffaire = async (id) => {
    try {
        const adminName = localStorage.getItem('admin_name');

        const deletedAffaireRef = doc(affaireCollection, id);
        const deletedAffaireSnapshot = await getDoc(deletedAffaireRef);
        const deletedAffaireData = deletedAffaireSnapshot.data();

        let deletedItemData = {
            nameAffaire: deletedAffaireData.nameAffaire,
            codeAffaire: deletedAffaireData.codeAffaire,
            deletedBy: adminName,
            deletedDate: new Date().toISOString()
        }

        // add the deleted obj to the DeletedItems 
        await addDoc(deletedItemsCollection, deletedItemData);

        // delete the doc 
        await deleteDoc(deletedAffaireRef);
    } catch (error) {
        //console.log(error)
    }
};

export const getAllAffairesForSelect = async () => {
    try {
        const querySnapshot = await getDocs(affaireCollection);
        const affaires = querySnapshot.docs.map((doc) => {
            const { nameAffaire, codeAffaire, tache } = doc.data();
            return {
                label: `${codeAffaire} - ${nameAffaire}`,
                value: codeAffaire,
                tache: tache ?? [] // Use nullish coalescing to handle undefined tache
            };
        });

        return affaires;
    } catch (error) {
        console.error("Error fetching affaires: ", error);
        return [];
    }
};