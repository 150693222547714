import React, { useEffect, useState } from 'react';
// Components :
import Sidebar from '../components/sidebar/Sidebar';
import { AddAffaireModal } from '../components/affairesComponenets/AddAffaireModal';
import { DeleteAffaireModal } from '../components/affairesComponenets/DeleteAffaireModal';
import { EditAffaireModal } from '../components/affairesComponenets/EditAffaireModal';
import { Pagination } from '../components/paginationComponent/Pagination';
// Functions :
import { getAffaires } from '../config/affairesConfig';
// Toast :
import { Toaster } from 'sonner';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// Icons :
import { BsDatabaseSlash } from 'react-icons/bs';
import SearchBar from '../components/searchBar/SearchBar';
import { Skeleton } from '@mui/material';

export const Affaires = () => {
    const [affaires, setAffaires] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredAffaires, setFilteredAffaires] = useState(affaires);
    const [loading, setLoading] = useState(false);

    const handleGetAffaires = async () => {
        try {
            setLoading(true);
            const result = await getAffaires();
            setLoading(false);
            setAffaires(result);
            setFilteredAffaires(result);
        } catch (error) {
            return [];
        }
    };

    useEffect(() => {
        handleGetAffaires();
    }, []);

    // ? ==============================|| Refresh Table ||=============================
    const handleEditAffaire = async () => {
        handleGetAffaires();
    };
    const handleDeleteAffaire = async () => {
        handleGetAffaires();
    };
    const handleAddAffair = async () => {
        handleGetAffaires();
    };
    // ? ==============================================================================

    // ? ===============================|| Pagination ||===============================
    const itemsPerPage = 20; // Set the number of items per page
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Assuming respoAffaires is your data array
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredAffaires.slice(indexOfFirstItem, indexOfLastItem);
    // ? ==============================================================================

    // ? =========================|| Handle Search Function ||=========================
    const handleSearch = (searchTerm) => {
        setSearchQuery(searchTerm);

        // If the search query is empty, display all affaires
        if (searchTerm.trim() === '') {
            setFilteredAffaires(affaires);
        } else {
            // Otherwise, filter the data based on the search term
            const filteredResults = affaires.filter(
                (affaire) =>
                    affaire.nameAffaire.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    affaire.codeAffaire.toLowerCase().includes(searchTerm.toLowerCase())
            );

            setCurrentPage(1);
            setFilteredAffaires(filteredResults);
        }
    };
    // ? ==============================================================================

    return (
        <div className="flex gap-2 md:gap-3 lg:gap-5 bg-[#F4F9FF]">
            <Sidebar />
            <main className="flex-1 mx-auto py-4 pr-4 h-screen overflow-y-auto">
                {/* <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme="colored"
                /> */}

                <Toaster expand={true} richColors />

                <div className='flex justify-between items-center mb-10'>
                    <p className='text-3xl lg:text-4xl font-bold'>Affaires</p>
                    <AddAffaireModal onAdd={() => handleAddAffair()} />
                </div>

                {/* Search Bar */}
                <SearchBar onSearch={handleSearch} placeholder='Recherche par nom ou code affaire' />

                <p className='text-gray text-sm mb-2'>Ici vous pouvez trouver les affaires et vous pouvez ajouter, modifier et supprimer une affaire.</p>
                <div className="overflow-x-scroll">
                    <table className='w-full overflow-hidden rounded-t-lg'>
                        <thead className='bg-primary text-white py-2'>
                            <tr>
                                <td className='py-3 text-center text-sm lg:text-base border border-white font-bold'>Code Affaire</td>
                                <td className='py-3 text-center text-sm lg:text-base border border-white font-bold'>Nom de l'affaire</td>
                                <td className='py-3 text-center text-sm lg:text-base border border-white font-bold'>Il a des taches</td>
                                <td className='py-3 text-center text-sm lg:text-base border border-white font-bold'>Action</td>
                            </tr>
                        </thead>

                        <tbody className='bg-white'>
                            {loading ? (
                                <tr>
                                    <td
                                        colSpan={4}
                                    >
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                    </td>
                                </tr>
                            ) :
                                currentItems.length > 0 ? (
                                    currentItems.map((affaire, i) => (
                                        <tr key={i}>
                                            <td className={`border border-gray text-center py-2 px-3 text-sm lg:text-base min-w-[100px] font-bold`}>{affaire?.codeAffaire}</td>
                                            <td className={`border border-gray text-center py-2 px-3 text-sm lg:text-base min-w-[300px]`}>{affaire?.nameAffaire}</td>
                                            <td className={`border border-gray text-center py-2 px-3 text-sm lg:text-base min-w-[300px]`}>
                                                {affaire?.tache?.length > 0 ? "Oui" : "Non"}
                                                </td>
                                            <td className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}>
                                                <div className='flex items-center justify-center'>
                                                    <EditAffaireModal
                                                        id={affaire?.affaire_id}
                                                        name={affaire?.nameAffaire}
                                                        code={affaire?.codeAffaire}
                                                        tache={affaire?.tache ?? []}
                                                        page={currentPage}
                                                        searchQuery={searchQuery}
                                                        onEdit={() => handleEditAffaire()}
                                                    />
                                                    <DeleteAffaireModal
                                                        id={affaire?.affaire_id}
                                                        name={affaire?.nameAffaire}
                                                        onDelete={() => handleDeleteAffaire()}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))) : (
                                    <tr>
                                        <td colSpan="4" className="text-center py-4 text-gray">
                                            <div className='flex flex-col justify-center items-center mt-4'>
                                                <BsDatabaseSlash size={30} className='mb-2' />
                                                <p>Pas de données disponibles.</p>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>

                <Pagination
                    itemsPerPage={itemsPerPage}
                    data={filteredAffaires}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                />
            </main>
        </div>
    )
}