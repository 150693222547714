import React, { useEffect, useState } from 'react';
// Material UI :
import { Box, Modal, IconButton, Tooltip } from '@mui/material';
// Icons :
import { AiOutlineEdit, AiOutlinePlus, AiOutlineClose } from 'react-icons/ai';
// Toast :
import { toast } from 'sonner';
import 'react-toastify/dist/ReactToastify.css';
// Functions :
import { editAffaire } from '../../config/affairesConfig';
// Assets : 
import Loading from '../../assets/images/white-loading.gif';

export const EditAffaireModal = ({ id, name, code, tache, onEdit, page, searchQuery }) => {
    const [codeAffaire, setCodeAffaire] = useState('');
    const [nameAffaire, setNameAffaire] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [tacheList, setTacheList] = useState(tache || []);
    const [newTache, setNewTache] = useState('');

    // Fill data when modal opens or props change
    useEffect(() => {
        setCodeAffaire(code);
        setNameAffaire(name);
        setTacheList(tache || []);
    }, [code, name, tache]);

    // ? ===============================|| Modal Style ||===============================
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [width, setWidth] = useState('50%');
    const [padding, setPadding] = useState(3);

    useEffect(() => {
        const currentWidth = window.innerWidth;
        if (currentWidth <= 500) {
            setWidth('90%');
            setPadding(2);
        }
    }, []);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        maxHeight: '95vh',
        background: 'white',
        borderRadius: 3,
        boxShadow: 24,
        p: padding,
    };
    // ? ===============================================================================

    // ? ==============================|| Edit Affaire ||===============================
    const handleEditAffaire = async () => {
        try {
            setIsLoading(true);
            await editAffaire(id, codeAffaire, nameAffaire, tacheList); // Include tacheList
            onEdit(); // Refresh table after editing an affair
            toast.success('L\'affaire a été modifiée avec succès');
            handleClose();
        } catch (error) {
            toast.warning("Il y a un problème, réessayez s'il vous plaît !");
        } finally {
            setIsLoading(false);
        }
    };
    // ? ===============================================================================

    // ? ===============================|| taches list ||===============================
    const handleAddTache = () => {
        if (newTache.trim() !== '') {
            setTacheList((prevTacheList) => [...prevTacheList, newTache]);
            setNewTache('');
        }
    };

    const handleDeleteTache = (index) => {
        const updatedTacheList = tacheList.filter((_, i) => i !== index);
        setTacheList(updatedTacheList);
    };

    const handleTacheKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleAddTache();
        }
    };
    // ? ===============================================================================

    return (
        <div>
            <Tooltip title="Modifier" onClick={handleOpen}>
                <IconButton>
                    <AiOutlineEdit size={20} color='green' />
                </IconButton>
            </Tooltip>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <p className='text-3xl font-bold'>Modifier Affaire</p>
                    <div className='mt-10'>
                        {/* Date Creation */}
                        <div className='mb-4'>
                            <p className='font-medium mb-2'>La Date</p>
                            <input
                                type='date'
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={selectedDate}
                                onChange={e => setSelectedDate(e.target.value)}
                            />
                        </div>

                        {/* Code Affaire */}
                        <div className='mb-4'>
                            <p className='font-medium mb-2'>Code Affaire</p>
                            <input
                                type='text'
                                placeholder='eg: 123456'
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={codeAffaire}
                                onChange={e => setCodeAffaire(e.target.value)}
                            />
                        </div>

                        {/* Name Affaire */}
                        <div className='mb-8'>
                            <p className='font-medium mb-2'>Nom d'Affaire</p>
                            <input
                                type='text'
                                placeholder="eg: nom d'affaire"
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={nameAffaire}
                                onChange={e => setNameAffaire(e.target.value)}
                            />
                        </div>

                        {/* Tache Input and Add Button */}
                        <div className='mb-8 flex flex-row items-center'>
                            <div className="w-5/6">
                                <p className='font-medium mb-2'>Les taches</p>
                                <input
                                    type='text'
                                    placeholder="eg: Nom de la tâche"
                                    className='border border-gray w-full rounded-md py-2 px-3'
                                    value={newTache}
                                    onChange={(e) => setNewTache(e.target.value)}
                                    onKeyPress={handleTacheKeyPress}
                                />
                            </div>

                            <Tooltip title="Ajouter">
                                <div
                                    className="w-1/6 flex justify-center ml-2 mt-8 bg-primary text-white rounded-md cursor-pointer"
                                    onClick={handleAddTache}
                                >
                                    <IconButton>
                                        <AiOutlinePlus size={25} color="white" />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </div>

                        {/* Display Tache List with Scrollbar */}
                        <div className="mb-8 max-h-40 overflow-y-auto">
                            {tacheList.map((tache, index) => (
                                <div key={index} className="flex justify-between items-center p-1 border-b border-gray">
                                    <span>{tache}</span>
                                    <IconButton onClick={() => handleDeleteTache(index)}>
                                        <AiOutlineClose size={20} color="red" />
                                    </IconButton>
                                </div>
                            ))}
                        </div>

                        {/* Buttons */}
                        <div className='flex justify-end items-center'>
                            <button
                                className='border border-gray rounded-md py-2 px-4 mr-4'
                                onClick={handleClose}
                            >
                                Annuler
                            </button>

                            <button
                                className='bg-primary text-white rounded-md py-2 px-4'
                                onClick={handleEditAffaire}
                            >
                                {isLoading ? <img src={Loading} alt='' className='w-6' /> : <p>Modifier</p>}
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};