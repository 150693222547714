import React, { useEffect, useState } from "react";
// ? Packages :
// Material UI :
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  IconButton,
  Modal,
  Tooltip,
} from "@mui/material";
// React-select :
import Select from "react-select";
// Icons :
import { AiOutlineEdit, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
// Toast :
import { toast } from "react-toastify";
// ? Functions & Firebase :
import { doc, setDoc } from "firebase/firestore";
import {
  getAffaires,
  getAllAffairesForSelect,
} from "../../config/affairesConfig";
import { pointageCollection } from "../../config/firebase";

export const EditHistoriqueModal = ({ data, onEdit }) => {
  const [affaires, setAffaires] = useState([]);
  const [affairesForSelect, setAffairesForSelect] = useState([]);
  // Tables
  const [normalWorkTable, setNormalWorkTable] = useState([
    ...data?.work?.normalWork,
  ]);
  const [extraWorkTable, setExtraWorkTable] = useState([
    ...data?.work?.extraWork,
  ]);
  // Taches :
  const [tache, setTache] = useState(false);
  const [tacheTable, setTacheTable] = useState([]);
  const [selectedTache, setSelectedTache] = useState("");
  const [showedTache, setShowedTache] = useState("");
  // Extra Work Taches :
  const [extraTache, setExtraTache] = useState(false);
  const [extraTacheTable, setExtraTacheTable] = useState([]);
  const [selectedExtraTache, setSelectedExtraTache] = useState("");
  const [showedExtraTache, setShowedExtraTache] = useState("");
  // Inputs :
  const [selectedAffaire, setSelectedAffaire] = useState("");
  const [selectedHours, setSelectedHours] = useState("");
  const [selectedExtraAffaire, setSelectedExtraAffaire] = useState("");
  const [selectedExtraHours, setSelectedExtraHours] = useState("");
  // this foor inputs select cuz 'react-select' doesnt take the value it takes the hole obj
  const [showedAffaire, setShowedAffaire] = useState();
  const [showedHours, setShowedHours] = useState("");
  const [showedExtraAffaire, setShowedExtraAffaire] = useState("");
  const [showedExtraHours, setShowedExtraHours] = useState("");
  // Checkboxs :
  const [transportCheck, setTransportCheck] = useState(data?.transport);
  const [deplacementCheck, setDeplacementCheck] = useState(data?.deplacement);
  const [holidayCheck, setHolidayCheck] = useState(data?.holiday);
  const [recuperationsCheck, setRecuperationsCheck] = useState(
    data?.recuperations
  );
  const [congeCheck, setCongeCheck] = useState(data?.conge);
  const [maladeCheck, setMaladeCheck] = useState(data?.malade);
  const [absentCheck, setAbsentCheck] = useState(data?.absent);
  // Prices :
  const [transportPrice, setTrnaportPrice] = useState(data?.transportPrice);
  const [deplacementPrice, setDeplacementPrice] = useState(
    data?.deplacementPrice
  );
  // Message :
  const [message, setMessage] = useState(data?.message);
  // Total Hours :
  const [totalNormalHours, setTotalNormalHours] = useState(
    data?.totalNormalwork
  );
  const [totalExtraHours, setTotalExtraHours] = useState(data?.totalExtrawork);
  // Hours :
  const normalHours = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
  ];
  const extraHours = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
    { label: "16", value: "16" },
  ];

  // ? =====================================|| Formate Date ||===================================
  let parts = data?.date.split("/");
  let formattedDate = `${parts[2]}-${parts[1].padStart(
    2,
    "0"
  )}-${parts[0].padStart(2, "0")}`;
  // ? ==========================================================================================

  // ? =====================================|| Checkboxs ||======================================
  const handleCheckboxChange = (n) => {
    if (n === 1) {
      setTransportCheck(!transportCheck);
    } else if (n === 2) {
      setDeplacementCheck(!deplacementCheck);
    } else if (n === 3) {
      setHolidayCheck(!holidayCheck);
    } else if (n === 4) {
      setRecuperationsCheck(!recuperationsCheck);
    } else if (n === 5) {
      setCongeCheck(!congeCheck);
    } else if (n === 6) {
      setMaladeCheck(!maladeCheck);
    } else if (n === 7) {
      setAbsentCheck(!absentCheck);
    }
  };
  // ? ==========================================================================================

  // ? ==============================|| Get Responsable Affaires ||==============================
  useEffect(() => {
    const handleGetAffaires = async () => {
      const result = await getAffaires();
      setAffaires(result);
    };
    handleGetAffaires();
  }, []);
  // ? ==========================================================================================

  // ? ==============================|| Get Affaires For Select ||===============================
  useEffect(() => {
    const handleGetAffairesForSelect = async () => {
      const result = await getAllAffairesForSelect();
      setAffairesForSelect(result);
    };
    handleGetAffairesForSelect();
  }, []);
  // ? ==========================================================================================

  // ? =====================================|| Modal Style ||====================================
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [width, setWidth] = useState("50%");
  const [padding, setPadding] = useState(3);

  const currentWidth = window.innerWidth;

  useEffect(() => {
    if (currentWidth <= 500) {
      setWidth("90%");
      setPadding(2);
    }
  }, [currentWidth]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width,
    maxHeight: "95vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: padding,
    borderRadius: 3,
    overflowY: "scroll",
  };
  // ? ==========================================================================================

  // ? ===============================|| Handle Selected Inputs ||===============================
  const handleSelectedAffaire = (e) => {
    setSelectedAffaire(e.value);
    setShowedAffaire(e);
    checkTach(e)
  };

  const handleSelectedTache = (e) => {
    setSelectedTache(e.value);
    setShowedTache(e);
  };

  const handleSelectedExtraTache = (e) => {
    setSelectedExtraTache(e.value);
    setShowedExtraTache(e);
  };
  const handleSelectedHours = (e) => {
    setSelectedHours(e.value);
    setShowedHours(e);
  };
  const handleSelectedExtraAffaire = (e) => {
    setSelectedExtraAffaire(e.value);
    setShowedExtraAffaire(e);
    checkExtraTach(e)
  };
  const handleSelectedExtraHours = (e) => {
    setSelectedExtraHours(e.value);
    setShowedExtraHours(e);
  };


  const handleTransportPrice = (e) => {
    setTrnaportPrice(e.target.value);
  };
  const handleDeplacementPrice = (e) => {
    setDeplacementPrice(e.target.value);
  };
  const handleMessage = (e) => {
    setMessage(e.target.value);
  };
  // ? ==========================================================================================

  // ? =======================================|| Taches ||=======================================
  const checkTach = (affaire) => {
    console.log(affaire)
    // Find the matching affaire in the affaires array
    const result = affairesForSelect.find(aff => aff.value === affaire.value); // Compare affaire.value

    if (result) { // Check if result is defined
      if (result.tache && result.tache.length > 0) { // Check if tache exists and has elements
        setTache(true);
        setTacheTable(result.tache);
        console.log("we have tache :", result.tache);
      } else {
        console.log("no tache");
        setTache(false);
        setTacheTable([]); // Clear tache options
      }
    } else {
      console.log("affaire not found in affaires array");
    }

  };

  const checkExtraTach = (affaire) => {
    // Find the matching affaire in the affaires array
    const result = affairesForSelect.find(aff => aff.value === affaire.value); // Compare affaire.value

    if (result) { // Check if result is defined
      if (result.tache && result.tache.length > 0) { // Check if tache exists and has elements
        setExtraTache(true);
        setExtraTacheTable(result.tache);
        console.log("we have tache :", result.tache);
      } else {
        console.log("no tache");
        setExtraTache(false);
        setExtraTacheTable([]); // Clear tache options
      }
    } else {
      console.log("affaire not found in affaires array");
    }

  };
  // ? ==========================================================================================

  // ? =======================================|| Toasts ||=======================================
  const notifySuccess = (message) =>
    toast.success(`${message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  const notifyInfo = (message) =>
    toast.info(`${message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  const notifyError = (message) =>
    toast.error(`${message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  // ? ===========================================================================================

  // ? ===================================|| Handle Tables ||=====================================
  const handleNormalHoursAdd = () => {
    try {
      // Check if tache is required and selected
      if (tache && selectedTache === "") {
        toast.info("Vous devez sélectionner une tache");
        return; // Exit the function if tache is required but not selected
      }

      // Check if affaire and hours are selected
      if (selectedAffaire !== "" && selectedHours !== "") {
        const newData = {
          codeAffaire: selectedAffaire,
          hours: selectedHours,
          tache: tache ? selectedTache : "-", // Include tache only if it's required
        };
        // Update total hours and table
        setTotalNormalHours(totalNormalHours + Number(selectedHours));
        setNormalWorkTable([...normalWorkTable, newData]);

        // Reset inputs
        setShowedAffaire("");
        setShowedHours("");

        // Reset tache input if it was used
        setShowedTache("");
        setTache(false);
        setTacheTable([]);
      }

      // Handle missing inputs
      if (selectedAffaire === "" && selectedHours === "") {
        toast.info("Vous devez sélectionner une affaire et les heures de l'affaire");
      } else if (selectedAffaire === "" && selectedHours !== "") {
        toast.info("Vous devriez sélectionner une affaire");
      } else if (selectedAffaire !== "" && selectedHours === "") {
        toast.info("Vous devez sélectionner les heures d'affaire");
      }
    } catch (error) {
      toast.warning("Il y a un problème, veuillez réessayer !");
    }
  };
  const handleExtraHoursAdd = () => {
    try {
      // Check if tache is required and selected
      if (extraTache && selectedExtraTache === "") {
        toast.info("Vous devez sélectionner une tache");
        return; // Exit the function if tache is required but not selected
      }

      if (selectedExtraAffaire !== "" && selectedExtraHours !== "") {
        const newData = {
          codeAffaire: selectedExtraAffaire,
          hours: selectedExtraHours,
          tache: extraTache ? selectedExtraTache : "-",
        };
        // Update total hours and table
        setTotalExtraHours(totalExtraHours + Number(selectedExtraHours));
        setExtraWorkTable([...extraWorkTable, newData]);

        // Reset inputs:
        setShowedExtraAffaire("");
        setShowedExtraHours("");

        // Reset tache input if it was used
        setShowedExtraTache("")
        setExtraTache(false)
        // setExtraTacheTable([])
      }

      // Handle missing inputs
      if (selectedExtraAffaire === "" && selectedExtraHours === "") {
        toast.info("Vous devez sélectionner une affaire et les heures de l'affaire")
      } if (selectedExtraAffaire === "" && selectedExtraHours !== "") {
        toast.info("Vous devriez sélectionner une affaire")
      } if (selectedExtraAffaire !== "" && selectedExtraHours === "") {
        toast.info("Vous devez sélectionner les heures d'affaire")
      }
    } catch (error) {
      // notifyError("Il y a un problème, veuillez réessayer");
      toast.warning('Il y a un problème, veuillez réessayer !')
    }
  };
  // ? ==========================================================================================

  // ? =============================|| Handel Delete From Table ||===============================
  const handleDeleteNormalWork = (index, hours) => {
    const updatedNormalWorkTable = [...normalWorkTable];

    updatedNormalWorkTable.splice(index, 1);

    setNormalWorkTable(updatedNormalWorkTable);

    setTotalNormalHours(totalNormalHours - hours);
  };
  const handleDeleteExtraWork = (index, hours) => {
    const updatedExtraWorkTable = [...extraWorkTable];

    updatedExtraWorkTable.splice(index, 1);

    setExtraWorkTable(updatedExtraWorkTable);

    setTotalExtraHours(totalExtraHours - hours);
  };
  // ? ==========================================================================================

  // ? ==============================|| Handle Update Pointage ||================================
  const handleUpdatePointage = async () => {
    const group_id = localStorage.getItem("group_id");

    try {
      const newPointageObj = {
        user_id: data?.user_id,
        group_id: group_id,
        verified: true,
        verifiedBy: localStorage.getItem("respo_name"),
        transport: transportCheck,
        transportPrice: transportPrice,
        deplacement: deplacementCheck,
        deplacementPrice: deplacementPrice,
        message: message,
        holiday: holidayCheck,
        normalWork: normalWorkTable,
        extraWork: extraWorkTable,
        totalNormalwork: totalNormalHours,
        totalExtrawork: totalExtraHours,
        totalHours: totalNormalHours + totalExtraHours,
        recuperations:
          recuperationsCheck === undefined ? false : recuperationsCheck,
        conge: congeCheck === undefined ? false : congeCheck,
        malade: maladeCheck === undefined ? false : maladeCheck,
        absent: absentCheck === undefined ? false : absentCheck,
        date: new Date(formattedDate),
      };

      if (
        normalWorkTable.length > 0 ||
        absentCheck ||
        maladeCheck ||
        congeCheck ||
        recuperationsCheck ||
        holidayCheck ||
        (deplacementCheck && deplacementPrice !== "") ||
        isSunday(formattedDate)
      ) {
        // const point = await addDoc(pointageCollection, newPointageObj);
        await setDoc(doc(pointageCollection, data?.id), newPointageObj, {
          merge: true,
        });
        onEdit(); // Refresh historique table after editing a pointage
        notifySuccess("Pointage modifié avec succès");
        handleClose();
      }
    } catch (error) {
      notifyError("Il y a un problème, veuillez réessayer");
      console.log("error :", error);
    }
  };

  function isSunday(dateString) {
    const date = new Date(dateString);
    return date.getDay() === 0; // 0 represents Sunday
  }
  // ? ==========================================================================================

  return (
    <div>
      <Tooltip title="Modifier" onClick={handleOpen}>
        <IconButton>
          <AiOutlineEdit size={20} color="green" />
        </IconButton>
      </Tooltip>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p className="text-3xl font-bold">Modifier Pointage</p>
          <div className="mt-6">
            <div>
              {/* Date */}
              <div>
                <p className="mb-1 font-medium">Date</p>
                <input
                  type="date"
                  className="w-full border border-gray p-1 rounded-md"
                  disabled
                  value={formattedDate}
                />
              </div>

              {/* Normal Work Table */}
              <div className="mt-4">
                <p className="mb-1 font-medium">Travail normal</p>
                <table className="w-full rounded-t-lg">
                  <thead className="bg-primary text-white py-2">
                    <th className="py-3 px-3 text-sm lg:text-base border border-white">
                      Affaire
                    </th>
                    <th className="py-3 px-3 text-sm lg:text-base border border-white">
                      tache
                    </th>
                    <th className="py-3 px-3 text-sm lg:text-base border border-white">
                      Heures d'affaire
                    </th>
                    <th className="py-3 px-3 text-sm lg:text-base border border-white">
                      Action
                    </th>
                  </thead>
                  <tbody>
                    <tr className="bg-light">
                      <td className="border border-gray py-2 px-3 text-sm lg:text-base">
                        <Select
                          options={affairesForSelect}
                          isSearchable={true}
                          value={showedAffaire}
                          onChange={handleSelectedAffaire}
                        />
                      </td>
                      <td className="border border-gray py-2 px-3 text-sm lg:text-base">
                        <Select
                          options={tacheTable.map(tache => ({
                            label: tache, // Use tache as the label
                            value: tache, // Use tache as the value
                          }))}
                          isSearchable={true}
                          value={showedTache}
                          onChange={handleSelectedTache}
                          isDisabled={!tache}
                        />
                      </td>
                      <td className="border border-gray py-2 px-3 text-sm lg:text-base">
                        <Select
                          options={normalHours}
                          value={showedHours}
                          isSearchable={true}
                          onChange={handleSelectedHours}
                        />
                      </td>
                      <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                        <div className="flex justify-center items-center">
                          <Tooltip
                            title="Ajouter"
                            onClick={() => handleNormalHoursAdd()}
                            disabled={
                              totalNormalHours + Number(selectedHours) > 8
                                ? true
                                : false
                            }
                          >
                            <IconButton>
                              <AiOutlinePlus size={20} color="blue" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                    {normalWorkTable.length > 0
                      ? normalWorkTable.map((d, index) => (
                        <tr key={index}>
                          <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                            {affaires.map((aff) => {
                              if (d.codeAffaire === aff.codeAffaire) {
                                return (
                                  <p key={aff.codeAffaire}>
                                    {aff.codeAffaire} - {aff.nameAffaire}
                                  </p>
                                );
                              }
                              return null;
                            })}
                          </td>
                          <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                            {d?.tache}
                          </td>
                          <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                            {d?.hours}
                          </td>
                          <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                            <div className="flex items-center justify-center cursor-pointer">
                              <Tooltip
                                title="Supprimer"
                                onClick={() =>
                                  handleDeleteNormalWork(index, d?.hours)
                                }
                              >
                                <IconButton>
                                  <AiOutlineDelete size={20} color="red" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </td>
                        </tr>
                      ))
                      : null}
                  </tbody>
                </table>
              </div>

              {/* Extra Work Table */}
              <div className="mt-4">
                <p className="mb-1 font-medium">Heures supplémentaires</p>
                <table className="w-full rounded-t-lg">
                  <thead className="bg-primary text-white py-2">
                    <th className="py-3 px-3 text-sm lg:text-base border border-gray">
                      Affaire
                    </th>
                    <th className="py-3 px-3 text-sm lg:text-base border border-gray">
                      tache
                    </th>
                    <th className="py-3 px-3 text-sm lg:text-base border border-gray">
                      Heures d'affaire
                    </th>
                    <th className="py-3 px-3 text-sm lg:text-base border border-gray">
                      Action
                    </th>
                  </thead>
                  <tbody>
                    <tr className="bg-light">
                      <td className="border border-gray py-2 px-3 text-sm lg:text-base">
                        <Select
                          options={affairesForSelect}
                          isSearchable={true}
                          value={showedExtraAffaire}
                          onChange={handleSelectedExtraAffaire}
                        />
                      </td>
                      <td className="border border-gray py-2 px-3 text-sm lg:text-base">
                        <Select
                          options={extraTacheTable.map(tache => ({
                            label: tache, // Use tache as the label
                            value: tache, // Use tache as the value
                          }))}
                          isSearchable={true}
                          value={showedExtraTache}
                          onChange={handleSelectedExtraTache}
                          isDisabled={!extraTache}
                        />
                      </td>
                      <td className="border border-gray py-2 px-3 text-sm lg:text-base">
                        <Select
                          options={extraHours}
                          isSearchable={true}
                          value={showedExtraHours}
                          onChange={handleSelectedExtraHours}
                        />
                      </td>
                      <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                        <div className="flex justify-center items-center">
                          <Tooltip title="Ajouter">
                            <IconButton onClick={() => handleExtraHoursAdd()}>
                              <AiOutlinePlus size={20} color="blue" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                    {extraWorkTable.length > 0
                      ? extraWorkTable.map((d, index) => (
                        <tr key={index}>
                          <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                            {affaires.map((aff) => {
                              if (d.codeAffaire === aff.codeAffaire) {
                                return (
                                  <p key={aff.codeAffaire}>
                                    {aff.codeAffaire} - {aff.nameAffaire}
                                  </p>
                                );
                              }
                              return null;
                            })}
                          </td>
                          <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                            {d?.tache}
                          </td>
                          <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                            {d?.hours}
                          </td>
                          <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                            <div className="flex items-center justify-center cursor-pointer">
                              <Tooltip
                                title="Supprimer"
                                onClick={() =>
                                  handleDeleteExtraWork(index, d?.hours)
                                }
                              >
                                <IconButton>
                                  <AiOutlineDelete size={20} color="red" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </td>
                        </tr>
                      ))
                      : null}
                  </tbody>
                </table>
              </div>

              {/* Déplacement & Trnasport & Holiday */}
              <FormGroup className="mt-4">
                {/* Déplacement */}
                <div className="flex flex-wrap justify-between items-center mb-2">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={deplacementCheck}
                        onChange={() => handleCheckboxChange(2)}
                      />
                    }
                    label="Déplacement"
                    labelPlacement="end"
                  />
                  <input
                    type="number"
                    value={deplacementPrice}
                    onChange={handleDeplacementPrice}
                    disabled={!deplacementCheck}
                    placeholder="Entrez le prix"
                    className="w-1/2 md:w-1/3 lg:w-1/4 px-2 py-2 rounded-md border border-gray"
                  />
                </div>

                {/* Trnasport */}
                <div className="flex flex-wrap justify-between items-center mb-2">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={transportCheck}
                        onChange={() => handleCheckboxChange(1)}
                      />
                    }
                    label="Transport"
                    labelPlacement="end"
                  />
                  <input
                    type="number"
                    value={transportPrice}
                    onChange={handleTransportPrice}
                    disabled={!transportCheck}
                    placeholder="Entrez le prix"
                    className="w-1/2 md:w-1/3 lg:w-1/4 px-2 py-2 rounded-md border border-gray"
                  />
                </div>

                {/* Holiday */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={holidayCheck}
                      onChange={() => handleCheckboxChange(3)}
                    />
                  }
                  label="Jour férié"
                  labelPlacement="end"
                />

                {/* Conge */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={congeCheck}
                      onChange={() => handleCheckboxChange(5)}
                    />
                  }
                  label="Congé"
                  labelPlacement="end"
                />

                {/* Malade */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={maladeCheck}
                      onChange={() => handleCheckboxChange(6)}
                    />
                  }
                  label="Malade"
                  labelPlacement="end"
                />

                {/* Absent  */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={absentCheck}
                      onChange={() => handleCheckboxChange(7)}
                    />
                  }
                  label="Absent(e)"
                  labelPlacement="end"
                />
              </FormGroup>

              {/* Message */}
              <div className="mt-4">
                <p className="mb-1 font-medium">Message</p>
                <input
                  type="text"
                  className="w-full border border-gray py-1 px-2 rounded-md outline-none"
                  value={message}
                  onChange={handleMessage}
                />
              </div>
            </div>

            <div className="flex justify-end items-center mt-4">
              <button
                onClick={handleClose}
                className="border border-gray rounded-md py-2 px-4 mr-4"
              >
                Annuler
              </button>

              <button
                // onClick={handleDeletePointage}
                className="bg-primary text-white rounded-md py-2 px-4"
                onClick={() => handleUpdatePointage()}
              >
                Modifier
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
